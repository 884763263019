<template>
<vSelect v-model='_value' :options='options' :placeholder='placeholder' v-on='$listeners' >
	<span slot='no-options'><p>No results</p></span>
</vSelect>
</template>

<script>
import vSelect from 'vue-select'
import Deselect from './SearchSelectInput.Deselect'
import OpenIndicator from './SearchSelectInput.OpenIndicator'

vSelect.props.components.default = () => ({ Deselect, OpenIndicator });

export default {
	name: 'SearchSelectInput',
	components: { vSelect },
	props: ['value', 'options', 'placeholder'],
	computed: {
		_value: {
			get() { return this.value },
			set(value) { 
				//console.log('oh hai!', value)
				this.$emit('input', value ) 
			}
		}
	}
}
</script>

<style lang='scss'>
$vs-border-radius: 0 !default;
$vs-component-line-height: $size-control-height;
$vs-dropdown-max-height: 373px !default;
$vs-border-color: $color-neutral-shadow;
$vs-border-width: 0;
$vs-dropdown-box-shadow: $box-shadow-lifted-on;
@import 'vue-select/src/scss/vue-select.scss';
.v-select {
	position: relative;
	background: $color-white;
	font-size: $size-font-standard;
	input { background: #fff !important; }
	.vs__selected {
		margin: 0;
		padding: 0 0 0 $size-gutter * 2;
		border: 0;
	}
	.vs__selected-options {
		margin: 0;
		padding: 0;
	}
	.vs__search {
		margin: 0;
		padding: 0 0 0 $size-gutter * 2;
		border: 0;
	}
	.vs__actions {
		position: absolute;
		z-index: 20;
		top: 0;
		right: 0;
		margin: 0;
		padding: 0;
		background: inherit;
	}
	.vs__dropdown-toggle {
		margin: 0;
		padding: 0;
	}	
	.vs__spinner { 
		margin-right: $size-gutter; 
	}
	.vs__dropdown-menu {
		padding: 0;
		margin: 0;
		border: 1px solid $color-neutral-shadow;
		left: -1px;
		width: calc(100% + 2px);
	}
	.vs__dropdown-option {
		padding: 0;
		padding: $size-gutter 0 $size-gutter $size-gutter * 2;
	}
	.vs__dropdown-option--highlight {
		background: $button-gradient-primary;
		color: $color-white;
	}
	.vs__clear { height: 16px; }
	&.vs--open {
		.vs__actions { display: none; }
	}

	&::-webkit-scrollbar { 
		display: block !important;
		all:unset !important;
	} 
}
</style>

